import React, { useEffect, useState } from "react";
import Latestjobs from "./components/Latestjobs";
import { Link } from "react-router-dom";
import { useBlogContext } from "./store/BlogContext";
import latestPostData from "./data/latestPostData";

export default function Blog() {
  const [postId, setPostId] = useState(1);

  const handleImageClick = (postId) => {
    setPostId(postId);
  };
  return (
    <div>
      <div className="bg-[#003060] py-44 bg">
        <h1 className="capitalize my-3 text-5xl font-bold text-center text-white">
          Blogs
        </h1>
        <p className="text-center text-white">
          <a href="/"  title="home">Home {">"} </a>
          <a href="/blogs"  title="blogs">Blogs</a>
        </p>
      </div>
      <div className="md:w-[85%] mx-auto py-5">
        <div className=" relative justify-around gap-3 flex-wrap my-12 items-start lg:p-0 p-5">
          <div className="md:w-full text-justify ">
            <div>
              {latestPostData
                .filter((post) => post.id === postId)
                .map((post) => (
                  <div>
                    <img className="md:w-[50%] py-10"  src={post.src} alt={` ${post.id}`} />
                    <h1 className="text-black font-bold text-3xl">
                      {post.heading}
                    </h1>
                    {post.content.map((item, index) => (
                      <div key={index}>
                        <h1>{item.heading}</h1>
                        <h3 className="text-black font-semibold text-xl">
                          {item.h}
                        </h3>
                        <p>{item.p}</p>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
          <div className="md:w-[40%] absolute top-10 right-10">
            <Latestjobs handleImageClick={handleImageClick} />
          </div>
        </div>
      </div>
    </div>
  );
}
