import React, { useEffect, useState } from "react";

export default function Cards(props) {
  return (
    <div className="shadow-[0_4px_4px_#000000_25%] p-3 text-center">
      <div class="bg-white hover:bg-[rgb(254,190,49)] cursor-pointer hover:scale-105 duration-500 hover:text-white p-3 rounded md:h-[290px]">
        <div className="h-24 my-2">
          <img src={props.img} alt="img1" className="w-100 mx-auto" />
        </div>
        <div className="">
          <h3 className="text-2xl py-2 font-bold">{props.title}</h3>
          <p className="text-base text-center">{props.subtitle}</p>
        </div>
      </div>
    </div>
  );
}
