("use client");
import { Link, NavLink } from "react-router-dom";
import { Button, Navbar } from "flowbite-react";
import { useEffect, useState } from "react";
// import logo from "../../src/img/Black Minimalist Fashion Brand Logo.svg";
import logoDark from "../../src/img/wellorgs_dark.svg";
// import logoLight from "../../src/img/wellorgs_blue_logo.jpeg";
import logoLight from "../../src/img/wellorgs_light_logo.svg";

export default function NavbarWithCTAButton() {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  return (
    <Navbar
      fluid
      rounded
      // className="md:absolute w-full sticky bg-transparent nav z-10"

      className={`w-full ${
        isSticky
          ? "sticky top-0 bg-white shadow-md z-10 transition-all duration-1000 ease-in-out"
          : "md:absolute bg-transparent nav z-10 border-b-[1px] border-gray-500 transition-all duration-1000 ease-in-out"
      } p-0`}
    >
      <Navbar.Brand href="/" className=" ">
        <img
          alt="Flowbite React Logo"
          // className="mr-3 w-12 pl-2 md:w-24 "
          className="mr-3 w-12 pl-2 md:w-[100px] lg:w-[120px]"
          // src={require("../img/logowhite.png")}
          // src={wellorgs_dark_logo}
          src={isSticky ? logoLight : logoDark}
        />
      </Navbar.Brand>
      <div className="flex md:order-2">
        <Link to="/contactus" onClick={scrollToTop}>
          <Button className="md:block hidden bg-[#FEBE31]">Get a quote</Button>
        </Link>
        <Navbar.Toggle className="hover:bg-transparent text-black" />
      </div>
      <Navbar.Collapse className="">
        <Navbar.Link href="#" className="text-black hover:bg-transparent">
          <p>
            <NavLink
              to="/"
              className={`text-${isSticky ? "black" : "white"} text-2xl`}
              onClick={scrollToTop}
            >
              Home
            </NavLink>
          </p>
        </Navbar.Link>
        <Navbar.Link href="#" className="text-black hover:bg-transparent">
          <NavLink
            to="/aboutus"
            className={`text-${isSticky ? "black" : "white"} text-2xl`}
            onClick={scrollToTop}
          >
            About Us
          </NavLink>
        </Navbar.Link>
        <Navbar.Link href="#" className="text-black hover:bg-transparent">
          <NavLink
            to="/blogs"
            className={`text-${isSticky ? "black" : "white"} text-2xl`}
            onClick={scrollToTop}
          >
            Blogs
          </NavLink>
        </Navbar.Link>
        <Navbar.Link href="#" className="text-black hover:bg-transparent">
          <NavLink
            to="/contactus"
            className={`text-${isSticky ? "black" : "white"} text-2xl`}
            onClick={scrollToTop}
          >
            Contact Us
          </NavLink>
        </Navbar.Link>

        <NavLink to="/contactus">
          <Button
            className="md:hidden my-2 block bg-[#FEBE31] hover:bg-[#003060]"
            // onClick={scrollToTop}
          >
            Get a quote
          </Button>
        </NavLink>
      </Navbar.Collapse>
    </Navbar>
  );
}
 