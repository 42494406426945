import React from "react";
import "aos/dist/aos.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { NavLink } from "react-router-dom";

const images = [
  // require("../img/pt2.jpg"),
  // require("../img/pt3.webp"),
  // require("../img/pt4.webp"),
  // require("../img/pt5.webp"),
  // require("../img/pt6.jpg"),
  // require("../img/pt7.jpg"),
  // require("../img/pt8.jpg"),
  // require("../img/pt9.webp"),
  // require("../img/pt10.webp"),
  // require("../img/pt11.webp"),

  require("../img/portfolio_images/1.png"),
  require("../img/portfolio_images/2.png"),
  require("../img/portfolio_images/3.png"),
  require("../img/portfolio_images/4.png"),
  require("../img/portfolio_images/5.png"),
  require("../img/portfolio_images/6.png"),
  require("../img/portfolio_images/7.png"),
  require("../img/portfolio_images/8.png"),
  require("../img/portfolio_images/9.png"),
  require("../img/portfolio_images/10.png"),
  require("../img/portfolio_images/11.png"),
  require("../img/portfolio_images/12.png"),
  require("../img/portfolio_images/13.png"),
  require("../img/portfolio_images/14.png"),
  require("../img/portfolio_images/15.png"),
];
export default function Portfolio() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <NavLink to="/contactus">
      <div className=" border-[#003060]" onClick={scrollToTop}>
        <div className="text-center md:w-full mx-auto py-5">
          <div
            className="my-5"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <h2>
              <span className="uppercase text-lg font-bold border-b-4  rounded py-1 border-[#F2B340]">
                Portfolio
              </span>
            </h2>
            <h2 className="capitalize my-3 text-5xl font-bold">
              Discover Our Portfolio
            </h2>

            <h3 className="text-base text-[#616161] md:w-1/2 mx-auto">
              A Showcase of Innovation, Expertise, and Success Stories.
            </h3>
          </div>
          <div
            className="p-5"
            data-aos="fade-up"
            data-aos-offset="300"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry gutter="15px">
                {images.map((image, i) => (
                  <div key={i} className="hover:scale-105">
                    <img
                      src={image}
                      className="md:transform md:hover:scale-105  md:duration-300 md:ease-in-out"
                      alt="sds"
                      title="image"
                    />
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>
      </div>
    </NavLink>
  );
}
