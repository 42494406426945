/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

export default function Location() {
  return (
    <div>
      <div className="relative">
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.321136680594!2d76.68690587473519!3d30.709371186762805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee56505158fd%3A0x309a981a318e16ed!2sXEAM%20Ventures%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1692254547845!5m2!1sen!2sin"
          height="450"
          width="100%"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe> */}

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3428.9622530249703!2d76.66659137462038!3d30.747561774577093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fefdc1c410841%3A0xc1743365eb982f68!2s4693%2C%20Sector%20125%2C%20Sunny%20Enclave%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Kharar%2C%20Punjab%20140301!5e0!3m2!1sen!2sin!4v1701952045460!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}
