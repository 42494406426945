import React from "react";

export default function Cardflip(props) {
  return (
    <div className="shadow-[0_4px_4px_#000000_25%] p-3 text-center">
      <div class="bg-white hover:bg-[#FEBE31] flex  items-center hover:scale-105 duration-500 hover:text-white p-6 rounded md:h-[290px]">
        <div className="">
          <p className="text-base text-justify">{props.dd}</p>
        </div>
      </div>
    </div>
  );
}
