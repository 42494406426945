import React from "react";

const NotFound = () => {
  return (
    <div>
      <div className="bg-[#003060] py-44 bg">
        <h1 className="capitalize   text-5xl font-bold text-center text-white">
          Page Not Found
        </h1>
        <p className="text-center text-white"></p>
      </div>
    </div>
  );
};

export default NotFound;
