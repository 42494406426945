const Sdata = [
  {
    img: require("../src/img/m6 1.png"),
    title: "Web design",
    subtitle:
      "Crafting Immersive Digital Experiences Elevate Your Brand with Our Web Design Expertise At Wellorgs.",
    dd: "Our team of creative minds and technical wizards collaborates to transform your digital presence into a dynamic and engaging platform. From sleek aesthetics to seamless functionality, we merge design with technology to ensure your website not only stands out but also delivers results.",
  },
  {
    img: require("../src/img/m5 1.png"),
    title: "Web development",
    subtitle:
      "Code that Shapes the Future Empowering Your Business with Dynamic Web Development  At Wellorgs.",
    dd: "Code that Shapes the Future Empowering Your Business with Dynamic Web Development  At Wellorgs, web development isn't just about writing lines of code; it's about architecting the digital landscape of tomorrow. Our team of skilled developers thrives on turning.",
  },
  {
    img: require("../src/img/m4 1.png"),
    title: "Digital marketing",
    subtitle:
      "Fueling Growth in the Digital Realm Unleash Your Brand's Potential with Our Dynamic Digital Marketing.",
    dd: "Fueling Growth in the Digital Realm Unleash Your Brand's Potential with Our Dynamic Digital Marketing Strategies Step into the realm of digital marketing, where creativity meets strategy to amplify your brand's online presence. Our team of experts crafts data-driven campaigns.",
  },
  {
    img: require("../src/img/m3 1.png"),
    title: "App development",
    subtitle:
      "Transforming Ideas into Functional Reality Experience the Power of Application Development.",
    dd: "Transforming Ideas into Functional Reality Experience the Power of Application Development Dive into the world of application development where concepts come to life. Our team of skilled developers is dedicated to turning your app ideas into functional and user-friendly realities.",
  },
  {
    img: require("../src/img/m2 1.png"),
    title: "Creative design",
    subtitle:
      "Crafting Creativity Where Imagination Meets Results in Creative Services Our creative.",
    dd: "Crafting Creativity Where Imagination Meets Results in Creative Services Our creative services transcend conventional boundaries, fusing imagination with strategy to give life to your brand's unique story. From captivating designs that speak volumes to content that resonates deeply.",
  },
  {
    img: require("../src/img/m1 1.png"),
    title: "Blog",
    subtitle:
      "Unveiling Insights Navigating the Digital Landscape through our Blogs Explore a treasure trove of insights.",
    dd: "Unveiling Insights Navigating the Digital Landscape through our Blogs Explore a treasure trove of insights, ideas, and inspiration in our blogs. Written with a blend of expertise and passion, our blog posts delve into a myriad of topics, offering valuable perspectives on industry trends.",
  },
];
export default Sdata;
