import React, { useState } from "react";
import Location from "./components/Location";
import { FaPhoneSquareAlt, FaEnvelope, FaHome } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ContactUs() {
  const [formState, setFormState] = useState({});

  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };
  console.log(formState.name, "formState");

  const submitHandler = (e) => {
    e.preventDefault();
    const config = {
      Host: "smtp.elasticemail.com",
      Username: "hello@wellorgs.com",
      Password: "28F1DFE9B48BF54D9A4FF1987DF30006E77E",
      To: "wellorgs@gmail.com",
      From: "hello@wellorgs.com",
      Subject: "This is from  my contact form",
      Body:
        "Name : " +
        formState.name +
        "<br> Last Name : " +
        formState.floating_last_name +
        "<br> Email : " +
        formState.email +
        "<br> Subject : " +
        formState.floating_subject +
        "<br> Help : " +
        formState.floating_help,
    };

    if (window.Email) {
      window.Email.send(config)
        .then(() => {
          toast.success("Email sent successfully");
          setFormState({}); // Reset form fields
        })
        .catch((error) => {
          toast.error(`Failed to send email: ${error.message}`);
        });
    }
  };

  return (
    <div className="overflow-x-hidden">
      <ToastContainer />
      <div className="bg-[#003060] py-44 bg">
        <h1 className="capitalize   text-5xl font-bold text-center text-white">
          Contact Us
        </h1>
        <p className="text-center text-white">
          <a href="/" title="home">
            Home {">"}{" "}
          </a>
          <a href="/contactus" title="contactus">
            Contact Us
          </a>
        </p>
      </div>
      <div className="text-center md:w-3/4 mx-auto pt-5">
        <div
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <h2>
            <span className="uppercase text-lg font-bold border-b-4  rounded py-1 border-[#F2B340]">
              Contact Us
            </span>
          </h2>
          <h1 className="capitalize my-3 mx-auto text-4xl font-bold md:w-7/12 w-full">
            Feel Free To Contact Us Today And Get Your Solution
          </h1>
          <h3 className="text-base text-[#616161] md:w-1/2 mx-auto">
            Need help with your Website/App Development? Need help with Digital
            Branding? Contact Us and we will provide you with the best offer!
          </h3>
        </div>
        <div className="flex justify-around flex-wrap gap-3 my-6">
          <div
            className="shadow p-5 md:w-3/5 w-full   text-left"
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <h1 className="text-3xl font-bold py-4">Get In Touch</h1>
            <p className="text-base text-[#616161]">
              Give us a call or drop by anytime, we endeavour to answer all
              enquiries within 24 hours.
            </p>
            <form className="my-5" onSubmit={submitHandler}>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="name"
                    value={formState.name || ""}
                    onChange={changeHandler}
                    id="floating_first_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#003060] focus:outline-none focus:ring-0 focus:border-[#003060] peer"
                    placeholder=" "
                    required
                  />
                  <label
                    for="floating_first_name"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#003060] peer-focus:dark:text-[#003060] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    First name
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="floating_last_name"
                    value={formState.floating_last_name || ""}
                    onChange={changeHandler}
                    id="floating_last_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#003060] focus:outline-none focus:ring-0 focus:border-[#003060] peer"
                    placeholder=" "
                    required
                  />
                  <label
                    for="floating_last_name"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#003060] peer-focus:dark:text-[#003060] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Last name
                  </label>
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="email"
                    name="email"
                    value={formState.email || ""}
                    onChange={changeHandler}
                    id="email"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#003060] focus:outline-none focus:ring-0 focus:border-[#003060] peer"
                    placeholder=" "
                    required
                  />
                  <label
                    for="email"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#003060] peer-focus:dark:text-[#003060] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Email
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="floating_subject"
                    value={formState.floating_subject || ""}
                    onChange={changeHandler}
                    id="floating_subject"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#003060] focus:outline-none focus:ring-0 focus:border-[#003060] peer"
                    placeholder=" "
                    required
                  />
                  <label
                    for="floating_subject"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#003060] peer-focus:dark:text-[#003060] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Subject
                  </label>
                </div>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <textarea
                  name="floating_help"
                  value={formState.floating_help || ""}
                  onChange={changeHandler}
                  id="floating_help"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-[#003060] focus:outline-none focus:ring-0 focus:border-[#003060] peer"
                  placeholder=" "
                  required
                ></textarea>
                <label
                  for="floating_help"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#003060] peer-focus:dark:text-[#003060] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  How can we help?
                </label>
              </div>

              <button
                type="submit"
                value="Send Email "
                className="text-white bg-[#FEBE31] hover:bg-[#003060]  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
              >
                Send message
              </button>
            </form>
          </div>
          <div
            className="md:w-[35%] w-full"
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <div className="shadow p-5 flex items-center flex-wrap text-left">
              <div className="sm:w-[25%] w-full">
                <h4 className="text-5xl">
                  <i className="text-[#FEBE31]">
                    <FaPhoneSquareAlt />
                  </i>
                </h4>
              </div>
              <div className="sm:w-[75%] w-full">
                <h1 className="font-bold text-xl">Call US</h1>
                <p className="text-[#616161]">+91-8303033000</p>
                {/* <p className="text-[#616161]">123 456 7891</p> */}
              </div>
            </div>
            <div className="shadow p-5 my-3 flex items-center flex-wrap text-left">
              <div className="sm:w-[25%] w-full">
                <h4 className="text-5xl">
                  <i className="text-[#FEBE31]">
                    <FaEnvelope />
                  </i>
                </h4>
              </div>
              <div className="sm:w-[75%] w-full">
                <h1 className="font-bold text-xl">Email US</h1>
                <p className="text-[#616161]">hello@wellorgs.com</p>
                {/* <p className="text-[#616161]">Wellorgs@gmail.com</p> */}
              </div>
            </div>
            <div className="shadow p-5 flex items-center flex-wrap text-left">
              <div className="sm:w-[25%] w-full">
                <h4 className="text-5xl">
                  <i className="text-[#FEBE31]">
                    <FaHome />
                  </i>
                </h4>
              </div>
              <div className="sm:w-[75%] w-full">
                <h1 className="font-bold text-xl">Address</h1>
                <p className="text-[#616161]">
                  {/* E-202, Indl Area, Phase 8-b Mohali, Mohali, Mohali 160059 */}
                  4693, Sector 125, Sunny Enclave, Sahibzada Ajit Singh Nagar,
                  Kharar, Punjab 140301
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Location />
    </div>
  );
}
