import React from "react";
import "aos/dist/aos.css";
// import clients_wellorgs from "../img/Clients_wellorgs.svg";
import clients_wellorgs from "../img/result.svg";
// import clients_wellorgs from "../img/Clints.png";
export default function Clints() {
  return (
    <div className="text-center  mx-auto py-5 my-5">
      <div
        className="my-5 container overflow-x-hidden"
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        <h2>
          <span className="uppercase text-lg font-bold border-b-4  rounded py-1 border-[#F2B340]">
            Clients
          </span>
        </h2>
        <h2 className="capitalize my-3 text-5xl font-bold">Our Clients</h2>

        <h3 className="text-base text-[#616161] md:w-1/2 text-justify mx-auto p-3">
          We take immense pride in the diverse array of businesses we've had the
          privilege to work with. From startups with big dreams to established
          enterprises seeking digital transformation, our clients span
          industries and ambitions. Each collaboration is a unique story of
          trust, innovation, and growth.
        </h3>
      </div>
      <img
        alt="clints"
        // src={require("../img/clients_wellorgs.svg")}
        src={clients_wellorgs}
        className="w-full"
        data-aos="fade-up"
        data-aos-offset="300"
        data-aos-easing="linear"
        data-aos-duration="1000"
      />
    </div>
  );
}
