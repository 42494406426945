import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimonialscards from "./Testimonialscards";
import "aos/dist/aos.css";
const Testimonials_data = [
  {
    para: "Wellorgs turned our concept into a website that wowed both us and our customers",
    img: require("../img/test1.png"),
    user: "Aditya Thakur",
    role: "Softwere Tester",
  },
  {
    para: "Their expertise in design and technology created a website that outshines the competition.",
    img: require("../img/test2.png"),
    user: "Vivek Walia",
    role: "Softwere Developer",
  },
  {
    para: "From start to finish, Wellorgs' team impressed us with their dedication and skill.",
    img: require("../img/test3.png"),
    user: "Kulbir Singh",
    role: "App Developer",
  },
  {
    para: "Wellorgs turned our concept into a website that wowed both us and our customers.",
    img: require("../img/test1.png"),
    user: "Aditya Thakur",
    role: "Softwere Tester",
  },
  {
    para: "Their expertise in design and technology created a website that outshines the competition.",
    img: require("../img/test2.png"),
    user: "Vivek Walia",
    role: "Softwere Developer",
  },
  {
    para: "From start to finish, Wellorgs' team impressed us with their dedication and skill.",
    img: require("../img/test3.png"),
    user: "Kulbir Singh",
    role: "App Developer",
  },
];

export default function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    lazyLoad: true,
    speed: 1500,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="text-center md:w-3/4 mx-auto py-5 my-5 p-1">
      <div>
        <div
          className="my-5"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <h2>
            <span className="uppercase text-lg font-bold border-b-4  rounded py-1 border-[#F2B340]">
              FEEDBACK
            </span>
          </h2>
          <h2 className="capitalize my-3 text-5xl font-bold">Testimonials</h2>

          <h3 className="text-base text-[#616161] md:w-1/2 text-justify mx-auto p-3">
            Voices of Trust Clients Share Their Wellorgs Experience. See how our
            solutions sparked success in the words of those who matter most –
            our clients. From dreams realized to challenges conquered, their
            stories are a testament to the power of collaboration and
            innovation. Join us in celebrating these narratives of growth and
            achievement.
          </h3>
        </div>
        <Slider {...settings}>
          {Testimonials_data.map((val) => {
            return (
              <Testimonialscards
                para={val.para}
                img={val.img}
                user={val.user}
                role={val.role}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
