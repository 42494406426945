import React, { useEffect, useState } from "react";
import Cards from "./Cards";
import Sdata from "../Sdata";
import ReactCardFlip from "react-card-flip";
import "aos/dist/aos.css";
import Cardflip from "./Cardflip";

export default function Services() {
  const [flip, setFlip] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [reset, setReset] = useState({ state: false, id: null });
  const updateData = Sdata.map((item, index) => {
    return {
      ...item,
      id: `card${index + 1}`,
    };
  });

  useEffect(() => {
    if (selectedCard) {
      setFlip(!flip);
    } else if (selectedCard === null && !!reset.state && !!reset.id) {
      const id = reset.id;
      setSelectedCard(id);
    }
  }, [selectedCard, reset.state, reset.id]);

  return (
    <div className="bg-[#C7D1DC] md:p-10 overflow-x-hidden w-full">
      <div className="text-center md:w-3/4 mx-auto py-5">
        <div
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          <h1>
            <span className="uppercase text-lg font-bold border-b-4  rounded py-1 border-[#F2B340]">
              Services
            </span>
          </h1>
          <h2 className="capitalize my-3 text-5xl font-bold">Our services</h2>

          <h3 className="text-base text-[#616161] md:w-1/2 mx-auto">
            At Wellorgs, our team of over 40 experts brings together a diverse
            range of skills. From crafting compelling content to developing
            sophisticated apps and websites, we combine our strengths to offer
            holistic solutions.
          </h3>
        </div>

        <div className="flex  justify-center flex-wrap  items-center my-12">
          {updateData.map((val) => {
            return (
              <div
                key={val.id}
                className="lg:w-2/6 w-full"
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="linear"
                data-aos-duration="1000"
                onClick={() => {
                  if (selectedCard === val.id) {
                    // setFlip(false);
                    // setSelectedCard(null);
                    // setReset({ state: true, id: val.id });
                  } else {
                    setSelectedCard(val.id);
                  }
                }}
              >
                {selectedCard !== val.id ? (
                  <div>
                    <Cards
                      img={val.img}
                      title={val.title}
                      subtitle={val.subtitle}
                      btn={val.btn}
                    />
                  </div>
                ) : (
                  <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
                    <div>
                      <Cardflip dd={val.dd} />
                    </div>

                    <div>
                      <Cardflip dd={val.dd} />
                      {/* <Cards subtitle={val.subtitle} btn={val.btn} /> */}
                    </div>
                  </ReactCardFlip>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
