import React from "react";
import Latestjobs from "./Latestjobs";
export default function Blogdetails() {
  return (
    <div>
      <div className="bg-[#003060] py-44 bg">
        <h1 className="capitalize my-3 text-5xl font-bold text-center text-white">
          Blog details
        </h1>
        <p className="text-center text-white">
          <a href="/">Home {">"} </a>
          <a href="/blog">Blog Details</a>
        </p>
      </div>
      <div className="md:w-[85%] mx-auto py-5">
        <div className="flex justify-around gap-3 flex-wrap my-12 items-start lg:p-0 p-5">
          <div className="md:w-[50%]">
            <div className="shadow">
              <img
                src={require("../img/about.png")}
                alt="about"
                className="w-full rounded"
              />
              <div className="p-5">
                <h1 className="text-xl font-bold capitalize">
                  Software development solutions for great business
                </h1>
                <p className="text-[#616161] py-5">
                  Entilators will be taken from certain New York hospitals and
                  redistributed to the worst-hit parts of the state under an
                  Entilators will be taken from certain New York hospitals and
                  redistributed to the worst-hit parts of the state under an
                  order to be signed by Governor Andrew Cuomo. New York saw its
                  highest single-day increase in deaths, up by 562 to 2,935 –
                  nearly half of all virus-related US deaths recorded yesterday.
                  The White House may advise those in virus hotspots to wear
                  face coverings in public to help stem the spread. A shortage
                  of several hundred ventilators in New York City, the epicentre
                  of the outbreak in the US, prompted Mr Cuomo to say that he
                  will order the machines be taken from various parts of the
                  state and give them to harder-hit areas. Amid a deepening
                  crisis, top health official Dr Anthony Fauci has said he
                  believes all states should issue stay-at-home orders. “I don’t
                  understand why that’s not happening,” Dr Fauci told CNN on
                  Thursday. “If you look at what’s going on in this country, I
                  just don’t understand why we’re not doing that.” “You’ve got
                  to put your foot on the accelerator to bring that number
                  down,” he added, referring to infection and death rates. on
                  the accelerator to bring that number down,” he added.
                </p>
                <p>
                  <a
                    href="/blogdetails"
                    className="bg-[#003060] px-5 py-2 rounded text-white"
                  >
                    Read More
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="md:w-[40%]">
            <Latestjobs />
          </div>
        </div>
      </div>
    </div>
  );
}
