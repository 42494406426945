import React from "react";
// import { FaHome } from "react-icons/fa";
import Services from "./components/Services";
import Counter from "./components/Counter";
import { FaUsers, FaNetworkWired, FaSmile, FaTrophy } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import aboutusImage from "../src/img/groupIam.jpeg";
export default function About() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div>
      {/* <div className="bg-[#003060] py-44">
        <img
          alt="banner"
          src={require("./img/bg.png")}
          className="w-full h-96"
        />
      </div> */}
      <div className="bg-[#003060] py-44 bg">
        <h1 className="capitalize   text-5xl font-bold text-center text-white">
          About Us
        </h1>
        <p className="text-center text-white">
          <a href="/" title="home">
            Home {">"}{" "}
          </a>
          <a href="/aboutus" title="about">
            About Us
          </a>
        </p>
      </div>
      <div className="md:w-[85%] mx-auto">
        <div className="flex justify-around gap-3 flex-wrap my-12 items-start lg:p-0 p-5">
          <div className="md:w-[58%]">
            <img
              // src={require("./img/about.png")}
              src={aboutusImage}
              alt="about"
              className="w-full rounded"
            />
          </div>
          <div className="md:w-[40%]">
            <h2>
              <span className="uppercase text-lg font-bold border-b-4  rounded py-1 border-[#F2B340]">
                About Us
              </span>
            </h2>
            <h1 className="capitalize my-3 text-4xl font-bold">
              Where creativity meets technology to build exceptional digital
              solutions.
            </h1>
            <h3 className="text-base text-[#616161] text-justify my-3 mx-auto">
              Welcome to Wellorgs Infotech – a visionary hub of digital
              transformation. With a passionate team of over 40 experts, we've
              been shaping exceptional digital solutions that redefine the
              industry.
            </h3>
            <div className="p-3 border-l-2  border-[#F2B340] box rounded">
              <p className="text-base text-justify text-[#616161]">
                Wellorgs Infotech was born from the desire to bridge the gap
                between creativity and technology. Our journey began with a
                commitment to craft digital experiences that go beyond the
                ordinary.
              </p>
              {/* <i className="flex justify-end text-4xl text-[#616161] text-right font-bold">
                <FaHome />
              </i> */}
            </div>

            <NavLink
              to="/contactus"
              className="hover:text-white flex justify-center"
              onClick={scrollToTop}
            >
              <button className="bg-[#003060]  py-3 w-[25%]  my-3 rounded text-white">
                Get In Touch
              </button>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="bg-[#003060] p-5  relative bottom-0 w-full">
        <div className="flex justify-center text-center align-middle items-center flex-wrap">
          <div
            className="md:w-[25%] w-full text-white py-2 lg:border-opacity-0 border-b-[1px] border-white"
            data-aos="zoom-in"
            data-aos-offset="300"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <i className="flex justify-center text-3xl font-bold">
              <FaUsers />
            </i>
            <h2 className="text-4xl font-semibold my-1">
              <Counter number={40} />
            </h2>
            <span>Team Member</span>
          </div>
          <div
            className="md:w-[25%] w-full text-white py-2 lg:border-opacity-0 border-b-[1px] border-white"
            data-aos="zoom-in"
            data-aos-offset="300"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <i className="flex justify-center text-3xl font-bold">
              <FaNetworkWired />
            </i>
            <h2 className="text-4xl font-semibold my-1">
              <Counter number={200} />
            </h2>
            <span>Total Project</span>
          </div>
          <div
            className="md:w-[25%] w-full text-white py-2 lg:border-opacity-0 border-b-[1px] border-white"
            data-aos="zoom-in"
            data-aos-offset="300"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <i className="flex justify-center text-3xl font-bold">
              <FaSmile />
            </i>
            <h2 className="text-4xl font-semibold my-1">
              <Counter number={1500} />
            </h2>
            <span>Happy Clients</span>
          </div>
          <div
            className="md:w-[25%] w-full text-white py-2 lg:border-opacity-0 border-b-[1px] border-white"
            data-aos="zoom-in"
            data-aos-offset="300"
            data-aos-easing="linear"
            data-aos-duration="1000"
          >
            <i className="flex justify-center text-3xl font-bold">
              <FaTrophy />
            </i>
            <h2 className="text-4xl font-semibold my-1">
              <Counter number={40} />
            </h2>
            <span>Awards Win</span>
          </div>
        </div>
      </div>
      <Services />
    </div>
  );
}
