import React, { useEffect } from "react";
import "./index.css";
import "./App.css";
import "flowbite";
import Aos from "aos";
import Home from "./Home";
import "aos/dist/aos.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./About";
import Blog from "./Blog";
import Blogdetails from "./components/Blogdetails";
import ContactUs from "./ContactUs";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { BlogProvider } from "./store/BlogContext";
import NotFound from "./components/NotFound";

export default function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div>
      <BlogProvider>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Aboutus" element={<About />} />
            <Route path="/Blogs" element={<Blog />} />
            <Route path="/Blogdetails" element={<Blogdetails />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </BlogProvider>
    </div>
  );
}
