import React from "react";
import { FaPhoneAlt, FaEnvelopeOpenText } from "react-icons/fa";
import { Button } from "flowbite-react";
import { NavLink } from "react-router-dom";
import logo from "../../src/img/wellorgs_blue_logo.jpeg";
export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div>
      <div className="relative">
        <div className="flex justify-center text-center relative md:absolute md:-top-12 w-full">
          <div className="bg-[#FEBE31] items-center md:space-y-0 space-y-6  p-5 md:w-1/2 w-full text-center flex justify-around flex-wrap  rounded">
            <div className="md:w-1/3 w-full">
              <FaPhoneAlt className="mx-auto text-2xl" />
              <h2 className="text-xl font-bold">+91-8303033000</h2>
            </div>
            <div className="md:w-1/3 w-full">
              <FaEnvelopeOpenText className="text-2xl mx-auto" />
              <h2 className="text-xl font-bold">hello@wellorgs.com</h2>
            </div>

            <div className="md:w-1/3  w-full">
              <NavLink to="/contactus" className="hover:text-white">
                <Button
                  className="bg-[#003060] mx-auto md:mt-3"
                  onClick={scrollToTop}
                >
                  Contact Us
                </Button>
              </NavLink>
            </div>
          </div>
        </div>
        <footer className="bg-[#003060] text-white pt-16">
          <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
            <div className="md:flex md:justify-around">
              <div className="flex md:justify-around flex-wrap gap-8 items-center ">
                <div className="md:w-40 relative">
                  <a href="/" title="home" className="flex items-center">
                    <img
                      // src={require("../img/logowhite.png")}
                      src={logo}
                      // className="mr-3 w-36"
                      className="mr-3 w-12 md:w-40 absolute right-12"
                      alt="FlowBite Logo"
                    />
                  </a>
                </div>
                <div className="my-3 md:w-1/3">
                  <h2 className="mb-3 text-lg font-semibold text-white uppercase ">
                    About us
                  </h2>
                  Welcome to Wellorgs Infotech – a visionary hub of digital
                  transformation. With a passionate team of over 40 experts,
                  we've been shaping exceptional digital solutions that redefine
                  the industry.
                </div>
                <div>
                  <h2 className="mb-3 text-lg font-semibold text-white uppercase ">
                    Support
                  </h2>
                  <ul className="text-white leading-9 font-medium">
                    <li>
                      <a href="/" title="home" className="hover:underline">
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        href="/aboutus"
                        title="aboutus"
                        className="hover:underline"
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="/blogs"
                        title="blogs"
                        className="hover:underline"
                      >
                        Blogs
                      </a>
                    </li>
                    <li>
                      <a
                        href="/contactus"
                        title="contactus"
                        className="hover:underline"
                        onClick={scrollToTop}
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:items-center text-center p-3 bg-[#F2B340] justify-center">
            <span className="text-base  text-dark text-center ">
              Copyright {""}
              <a href="/" title="home" className="hover:underline">
                © Wellorgs Infotech {""}
              </a>
              2023 All Rights Reserved
            </span>
          </div>
        </footer>
      </div>
    </div>
  );
}
