import React from "react";
import Counter from "./Counter";
// import ReactPlayer from "react-player";
import "aos/dist/aos.css";
import { FaUsers, FaNetworkWired, FaSmile, FaTrophy } from "react-icons/fa";
// import videoUrl from "../video/headerVideo.mov";
// import VideoPlayer from "./VideoPlayer";

// import video from "../../src/video/bannerVideo.mp4";
import video from "../../src/video/finalVideo.mp4";
export default function Banner() {
  const handleVideoClick = (event) => {
    // Prevent default action (pausing the video)
    event.preventDefault();
  };

  return (
    <div className="relative">
      {/* <img alt="banner" src={require("../img/bg.png")} className="w-full" /> */}
      <div className="">
        <video
          width={1380}
          autoPlay
          loop
          muted
          controls
          onClick={handleVideoClick}
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="bg-[#003060] p-5 md:absolute relative bottom-0 w-full">
        <div className="flex justify-center text-center align-middle items-center flex-wrap">
          <div
            className="md:w-[25%] w-full text-white py-2 lg:border-opacity-0 border-b-[1px] border-white"
            // data-aos="zoom-in"
            // data-aos-offset="300"
            // data-aos-easing="linear"
            // data-aos-duration="1000"
          >
            <i className="flex justify-center text-3xl font-bold">
              <FaUsers />
            </i>
            <h2 className="text-4xl font-semibold my-1">
              <Counter number={40} />
            </h2>
            <span>Team Members</span>
          </div>
          <div
            className="md:w-[25%] w-full text-white py-2 lg:border-opacity-0 border-b-[1px] border-white"
            // data-aos="zoom-in"
            // data-aos-offset="300"
            // data-aos-easing="linear"
            // data-aos-duration="1000"
          >
            <i className="flex justify-center text-3xl font-bold">
              <FaNetworkWired />
            </i>
            <h2 className="text-4xl font-semibold my-1">
              <Counter number={200} />
            </h2>
            <span>Total Projects</span>
          </div>
          <div
            className="md:w-[25%] w-full text-white py-2 lg:border-opacity-0 border-b-[1px] border-white"
            // data-aos="zoom-in"
            // data-aos-offset="300"
            // data-aos-easing="linear"
            // data-aos-duration="1000"
          >
            <i className="flex justify-center text-3xl font-bold">
              <FaSmile />
            </i>
            <h2 className="text-4xl font-semibold my-1">
              <Counter number={1500} />
            </h2>
            <span>Happy Clients</span>
          </div>
          <div
            className="md:w-[25%] w-full text-white py-2 lg:border-opacity-0 border-b-[1px] border-white"
            // data-aos="zoom-in"
            // data-aos-offset="300"
            // data-aos-easing="linear"
            // data-aos-duration="1000"
          >
            <i className="flex justify-center text-3xl font-bold">
              <FaTrophy />
            </i>
            <h2 className="text-4xl font-semibold my-1">
              <Counter number={40} />
            </h2>
            <span>Awards Won</span>
          </div>
        </div>
      </div>
    </div>
  );
}

// import React from "react";
// import Counter from "./Counter";
// import "aos/dist/aos.css";
// import { FaUsers, FaNetworkWired, FaSmile, FaTrophy } from "react-icons/fa";
// // import VideoPlayer from "./VideoPlayer";

// import video from "../../src/video/bannerVideo.mp4";
// export default function Banner() {
//   const handleVideoClick = (event) => {
//     // Prevent default action (pausing the video)
//     event.preventDefault();
//   };

//   return (
//     <div className="relative">
//       {/* <img alt="banner" src={require("../img/bg.png")} className="w-full" /> */}
//       <div className="">
//         <video
//           width={1380}
//           autoPlay
//           loop
//           muted
//           controls
//           onClick={handleVideoClick}
//         >
//           <source src={video} type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//       </div>
//       <div className="bg-[#003060] p-5 md:absolute relative bottom-0 w-full">
//         <div className="flex justify-center text-center align-middle items-center flex-wrap">
//           <div
//             className="md:w-[25%] w-full text-white py-2 lg:border-opacity-0 border-b-[1px] border-white"
//             // data-aos="zoom-in"
//             // data-aos-offset="300"
//             // data-aos-easing="linear"
//             // data-aos-duration="1000"
//           >
//             <i className="flex justify-center text-3xl font-bold">
//               <FaUsers />
//             </i>
//             <h2 className="text-4xl font-semibold my-1">
//               <Counter number={40} />
//             </h2>
//             <span>Team Member</span>
//           </div>
//           <div
//             className="md:w-[25%] w-full text-white py-2 lg:border-opacity-0 border-b-[1px] border-white"
//             data-aos="zoom-in"
//             data-aos-offset="300"
//             data-aos-easing="linear"
//             data-aos-duration="1000"
//           >
//             <i className="flex justify-center text-3xl font-bold">
//               <FaNetworkWired />
//             </i>
//             <h2 className="text-4xl font-semibold my-1">
//               <Counter number={200} />
//             </h2>
//             <span>Total Project</span>
//           </div>
//           <div
//             className="md:w-[25%] w-full text-white py-2 lg:border-opacity-0 border-b-[1px] border-white"
//             data-aos="zoom-in"
//             data-aos-offset="300"
//             data-aos-easing="linear"
//             data-aos-duration="1000"
//           >
//             <i className="flex justify-center text-3xl font-bold">
//               <FaSmile />
//             </i>
//             <h2 className="text-4xl font-semibold my-1">
//               <Counter number={1500} />
//             </h2>
//             <span>Happy Clients</span>
//           </div>
//           <div
//             className="md:w-[25%] w-full text-white py-2 lg:border-opacity-0 border-b-[1px] border-white"
//             data-aos="zoom-in"
//             data-aos-offset="300"
//             data-aos-easing="linear"
//             data-aos-duration="1000"
//           >
//             <i className="flex justify-center text-3xl font-bold">
//               <FaTrophy />
//             </i>
//             <h2 className="text-4xl font-semibold my-1">
//               <Counter number={40} />
//             </h2>
//             <span>Awards Win</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
