import React from "react";
import Banner from "./components/Banner";
import Services from "./components/Services";
import Portfolio from "./components/Portfolio";
import Clints from "./components/Clints";
import Testimonials from "./components/Testimonials";
import Qoute from "./components/Qoute";
import Location from "./components/Location";
export default function Home() {
  return (
    <div>
      <Banner />
      <Services />
      <Portfolio />
      <Clints />
      <Testimonials />
      <Qoute />
      <Location />
    </div>
  );
}
