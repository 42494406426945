import React, { useState } from "react";
import latestPostData from "../data/latestPostData";
import { useBlogContext } from "../store/BlogContext";

export default function Latestjobs({ handleImageClick }) {
  return (
    <div>
      <div className="p-3 border-l-2 border-[#F2B340] box rounded">
        <h1>
          <span className="text-xl font-bold border-b-2 border-[#003060]">
            Latest Posts
          </span>
        </h1>
        <div className="text-base text-[#616161]">
          <div className="flex flex-col gap-3 my-3">
            {latestPostData.map((post) => (
              <div
                className="flex gap-3"
                key={post.id}
                onClick={() => handleImageClick(post.id)}
              >
                <div className="w-[25%]">
                  <img
                    src={post.src}
                    alt={` ${post.id}`}
                    className="w-full rounded"
                  />
                </div>
                <div className="w-[75%]">
                  <h2 className="text-lg font-bold text-black">
                    {post.heading}
                  </h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
