import React, { createContext, useState, useContext } from "react";

const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const setImage = (imageData) => {
    setSelectedImage(imageData);
  };

  return (
    <BlogContext.Provider value={{ selectedImage, setImage }}>
      {children}
    </BlogContext.Provider>
  );
};

export const useBlogContext = () => {
  return useContext(BlogContext);
};
