import React from "react";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
export default function Qoute() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <div className="mt-5">
      <NavLink to="/contactus">
        <img
          alt="qoute"
          onClick={scrollToTop}
          src={require("../img/Quote.png")}
          className="w-full"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="linear"
          data-aos-duration="1000"
        />
      </NavLink>
    </div>
  );
}
