import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import "aos/dist/aos.css";
export default function Testimonialscards(props) {
  return (
    <div>
      <div
        className="p-3 container overflow-x-hidden"
        data-aos="fade"
        data-aos-offset="300"
        data-aos-easing="linear"
        data-aos-duration="1000"
      >
        <div className="bg-[#003060] p-5 rounded text-white">
          <i className="flex justify-center text-3xl font-bold py-3">
            <FaQuoteLeft />
          </i>
          <p>{props.para}</p>
          <div className="flex flex-wrap space-x-2 py-3 items-center justify-center justify-content-center  text-center">
            <div className="mx-4">
              <img src={props.img} className="w-full" alt="fdf" />
            </div>
            <div className="">
              <h6 className="text-xl">{props.user}</h6>
              <h6 className="text-base">{props.role}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
